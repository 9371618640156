import React from 'react'
import { Layout, SEO } from '../components'

const GdprPage = () => (
  <Layout>
    <SEO
      title="Kişisel Verilerin Korunması"
      keywords={[`gatsby`, `application`, `react`]}
    />
  </Layout>
)
export default GdprPage
